import React from 'react';
import Navbar from "../component/Navbar/Navbar";
import Footer from "../component/Footer/Footer";
import { lan } from "../lan/index.js";
import MetaTags from '../component/MetaTags.jsx';

const PrivacyPolicy = () => {
  const data = lan.en;
  return (
    <>
    <Navbar />
    
    <div className="container">
    <div className="centered-content">

      <h1>Privacy Policy</h1>

      <h4>1. Information We Collect</h4>
      <p>We may collect personal information that you provide directly when you use our services.</p>

      <h4>2. How We Use Your Information</h4>
      <p>We may use the information we collect for various purposes, including:</p>
      <ul>
        <li>Providing and maintaining our services.</li>
        <li>Notifying you about changes to our services.</li>
        <li>Allowing you to participate in interactive features of our services when you choose to do so.</li>
        <li>Providing customer support.</li>
        {/* Add more purposes as necessary */}
      </ul>

      <h4>3. Cookies</h4>
      <p>We use cookies to improve your experience on our site. By using our site, you consent to the use of cookies.</p>

      <h4>4. Security</h4>
      <p>We take reasonable steps to protect your personal information. However, no method of transmission over the internet is entirely secure.</p>

      <h4>5. Changes to This Privacy Policy</h4>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

      <h4>6. Contact Us</h4>
      <p>If you have any questions or suggestions about our Privacy Policy, please contact us.</p>

      {/* You should add more sections based on your website's specific practices. */}
    </div>
    </div>
    <Footer value={data} />
    <MetaTags title={"Privacy Policy | Koderbox"} />
    </>
  );
};

export default PrivacyPolicy;
