import React from "react";
import { Helmet } from "react-helmet";
import Logo from "../static/img/logo.jpeg";

const MetaTags = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} aria-hidden="true" />
      <span class="visually-hidden">Koderbox Technology</span>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description?.substring(0,200)} />
      <meta property="og:type" content={"website"} />
      <meta property="og:image" content={Logo} />
      {Logo && <meta property="og:image:alt" content={"koderbox"} />}
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="400" />
      <meta property="og:site_name" content="koderbox" />
      {/* <meta property="og:url" content="https://social-network.com" /> */}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@koderbox" />
      <meta name="twitter:creator" content="@Koderbox" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={Logo} />
      {Logo && <meta name="twitter:image:alt" content={"koderbox"} />}
    </Helmet>
  );
};

export default MetaTags;
